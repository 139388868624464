<template>
  <article v-if="blogpostData.length" class="blogpost container">
    <template v-for="(item, index) in blogpostData" :key="index">
      <h1 class="heading__main">{{ item.title }}</h1>

      <p class="blogpost__summary">{{ item.summary }}</p>

      <img class="blogpost__pageImage" :src="item.pageImage" :alt="item.pageImageAlt" />

      <div v-html="item.content" class="blogpost__content"></div>

      <div v-if="item.relatedImages.length" class="blogpost__images">
        <Gallery :galleryInput="item.relatedImages" :imgSrc="'url'" :imgAlt="'description'" />
      </div>
    </template>
  </article>

  <div v-if="errorMessage" class="blogpost not-found">
    <h1>404</h1>
    <p>Deze blogpost kan niet worden weergegeven</p>
    <button @click="goBack">Ga terug</button>
  </div>
</template>

<script>
import { computed, ref } from 'vue'
import { Client } from "@/client"

import './blog.scss'

import Gallery from '@/components/gallery/Gallery.vue'

export default {
  name: "Blogpost",
  props: ['slug'],
  components: {
    Gallery
  },
  setup(props) {
    const blogpost = ref([])
    const error = ref([])

    const monthNames = [
        "januari", "februari", "maart", "april", "mei", "juni", "juli", "augustus", "september", "oktober", "november", "december"
    ];

    const categories = {
      main: "Algemeen", event: "Evenement", tips: "Tips & tricks"
    }

    Client 
      .items()
      .type("blogpost")
      .equalsFilter("elements.slug", props.slug)
      .toObservable()
      .subscribe(
        response => blogpost.value = response.items,
        err => error.value = err.message
      )

    const blogpostData = computed(() => blogpost.value.map((blogpost) => ({ 
      title: blogpost.title.value,
      day: blogpost.date.value.getDate(),
      month: monthNames[blogpost.date.value.getMonth()],
      year: blogpost.date.value.getFullYear(),
      summary: blogpost.summary.value, 
      category: categories[blogpost.category.value[0].codename], 
      pageImage: blogpost.page_image.value[0].url,
      pageImageAlt: blogpost.page_image.value[0].description,
      content: blogpost.content.value,
      relatedImages: blogpost.related_images.value,
    })))

    const errorMessage = computed(() => {
      if(error.value.length) {
        console.log(error.value)
        return 'Oeps! Er ging iets mis bij het laden van de content...'
      }
    })

    return {
        blogpostData,
        errorMessage
    }
  },
  methods: {
    goBack() {
      this.$router.go(-1)
    }
  }
}
</script>